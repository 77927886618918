import {Box, Button, Center, Container, Heading, Image, Text} from "@chakra-ui/react";
import packageImage from '../../assets/products/pack.png';
import {useNavigate} from "react-router-dom";
import useStore from "../../storage/DataStore";
import {FormattedMessage} from "react-intl";

function Home() {
    let navigate = useNavigate();
    const nextStep = function (gender) {
        useStore.setState({
            gender: gender
        });

        navigate('/step2', {replace: true});
    }

    return (
        <Container p={[1, 5]}>
            <Box
                background={"white"}
                borderWidth="1px"
                borderRadius="lg"
                p={[3, 5]}
                mt={1}
                textAlign={"center"}
            >
                <Heading mb={3} fontSize={[28, 42]}>
                    <FormattedMessage id="home.heading" />
                </Heading>

                <Box p={1} mb={2}>
                    <Center>
                        <Image src={packageImage} alt={'BODY+'} maxH={150}/>
                    </Center>
                </Box>

                <Text mb={3}>
                    <FormattedMessage id="home.text" />
                </Text>

                <Button p={10} mb={4} onClick={() => nextStep('male')} width={'100%'} background={'#d9f1ef'}
                        fontSize={'xl'}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                         className="bi bi-gender-male" viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                              d="M9.5 2a.5.5 0 0 1 0-1h5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-1 0V2.707L9.871 6.836a5 5 0 1 1-.707-.707L13.293 2H9.5zM6 6a4 4 0 1 0 0 8 4 4 0 0 0 0-8z"/>
                    </svg>

                    <Text pl={2}><FormattedMessage id="home.gender.male" /></Text>
                </Button>

                <Button p={10} onClick={() => nextStep('female')} width={'100%'} background={'#f1608c'} color={'white'}
                        fontSize={'xl'}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                         className="bi bi-gender-female" viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                              d="M8 1a4 4 0 1 0 0 8 4 4 0 0 0 0-8zM3 5a5 5 0 1 1 5.5 4.975V12h2a.5.5 0 0 1 0 1h-2v2.5a.5.5 0 0 1-1 0V13h-2a.5.5 0 0 1 0-1h2V9.975A5 5 0 0 1 3 5z"/>
                    </svg>

                    <Text pl={2}><FormattedMessage id="home.gender.female" /></Text>
                </Button>
            </Box>
        </Container>
    );
}

export default Home;
